import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { InputText, JustSelect, Modal, useDebounceFn, usePromiseProcessing } from '@just-ai/just-ui';
import { IntegrationDto } from '@just-ai/api/dist/generated/Integrations';

import { useAppContext } from 'contexts/AppContext';
import { t } from 'localization';

import { ManagerNotifyResult } from '../../../index';
import styles from '../styles.module.scss';
import { AxiosError } from 'axios';

type GoogleSettingsModalProps = {
  onSubmit: (integration: ManagerNotifyResult['googleSheets']) => void;
  onCancel: () => void;
  integration?: IntegrationDto;
  spreadsheetId: string;
  sheetId: string;
  currentBot?: any;
  allIntegrations: IntegrationDto[];
  isOpen: boolean;
};
function GoogleSettingsModal({
  isOpen,
  integration,
  spreadsheetId,
  sheetId,
  allIntegrations = [],
  onSubmit,
  onCancel,
}: GoogleSettingsModalProps) {
  const { integrationsService } = useAppContext();
  const [currentIntegrationId, setCurrentIntegrationId] = useState(integration?.integrationId);
  const [currentSpreadsheetId, setCurrentSpreadsheetId] = useState(spreadsheetId);
  const [currentSheetId, setCurrentSheetId] = useState(sheetId);
  const [errorSpreadsheetId, setErrorSpreadsheetId] = useState<string | undefined>();

  const [getListOfSheetsState, getListOfSheetsApi] = usePromiseProcessing(
    (currentSheetId: string) => {
      return integrationsService.getListOfSheets(currentSheetId, currentIntegrationId!).then(({ data: sheets }) =>
        sheets.map(sheet => ({
          label: sheet.sheetName,
          value: sheet.sheetName,
        }))
      );
    },
    {
      onError: e => {
        if (e instanceof AxiosError && e.response?.data) {
          if (e.response?.data.error === 'integrationadapter.common.google_json_response_exception') {
            setErrorSpreadsheetId(t('Wizard:ManagerNotify:Google:Settings:Table:Error'));
          }
        }
      },
      deps: [integrationsService, currentIntegrationId],
    }
  );

  useEffect(() => {
    if (!isOpen) {
      getListOfSheetsState.reset();
      setCurrentSpreadsheetId('');
      setCurrentSheetId('');
      return;
    }
    setCurrentIntegrationId(integration?.integrationId);
    setCurrentSpreadsheetId(spreadsheetId);
    if (spreadsheetId) getListOfSheetsApi(spreadsheetId);
    setCurrentSheetId(sheetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!currentSpreadsheetId) return;
    getListOfSheetsApi(currentSpreadsheetId);
  }, [getListOfSheetsApi, currentSpreadsheetId]);

  useEffect(() => {
    if (currentSheetId) return;
    if (!getListOfSheetsState.result || getListOfSheetsState.result.length === 0) return;
    setCurrentSheetId(getListOfSheetsState.result![0].value);
  }, [getListOfSheetsState.result, currentSheetId]);

  const onChangeIntegration = useCallback(
    val => {
      const oneEl = Array.isArray(val) && val.length > 0 ? val[0] : '';
      getListOfSheetsState.reset();
      setCurrentIntegrationId(oneEl.toString());
      setCurrentSpreadsheetId('');
      setCurrentSheetId('');
    },
    [getListOfSheetsState]
  );

  const onChangeSpreadsheet = useCallback(
    async val => {
      setErrorSpreadsheetId(undefined);
      getListOfSheetsState.reset();
      setCurrentSpreadsheetId(val);
      setCurrentSheetId('');
    },
    [getListOfSheetsState]
  );

  const debouncedInputValue = useDebounceFn(onChangeSpreadsheet, 1000);

  const onChangeSheet = useCallback(val => {
    const oneEl = Array.isArray(val) && val.length > 0 ? val[0] : '';
    setCurrentSheetId(oneEl.toString());
  }, []);

  const canSubmit = useMemo(() => {
    return currentIntegrationId && currentSpreadsheetId && currentSheetId;
  }, [currentIntegrationId, currentSheetId, currentSpreadsheetId]);

  const submit = useCallback(() => {
    if (!currentSpreadsheetId) {
      console.error(`Cannot found spreadsheet by id ${currentSpreadsheetId}`);
      return;
    }
    onSubmit({
      enabled: true,
      integrationId: currentIntegrationId!,
      spreadsheetId: currentSpreadsheetId,
      sheetName: currentSheetId,
    });
    onCancel();
  }, [currentIntegrationId, currentSheetId, currentSpreadsheetId, onCancel, onSubmit]);

  return (
    <Modal
      title={t('Wizard:ManagerNotify:Google:Settings:Title')}
      buttonSubmitText={t('Wizard:ManagerNotify:Google:Settings:Submit')}
      buttonCancelText={t('Cancel')}
      buttonSubmitColor='primary'
      buttonSubmitDisabled={!canSubmit}
      onCancelClick={onCancel}
      onActionClick={submit}
      centered
      isOpen={isOpen}
    >
      <div className={styles.settingsModalContent}>
        <div className={styles.dropdownWrapper}>
          <div>{t('Wizard:ManagerNotify:Google:Settings:Account')}</div>
          <JustSelect
            fullWidth
            onChange={onChangeIntegration}
            options={allIntegrations.map(integration => ({
              label: integration.integrationName,
              value: integration.integrationId,
            }))}
            value={currentIntegrationId}
            position='fixed'
          />
        </div>
        <div className={styles.dropdownWrapper}>
          <div>{t('Wizard:ManagerNotify:Google:Settings:TableID')}</div>
          <InputText
            placeholder={t('Wizard:ManagerNotify:Google:Settings:Table:Placeholder')}
            fullWidth
            onChange={debouncedInputValue}
            disabled={!currentIntegrationId}
            defaultValue={currentSpreadsheetId}
            errorText={errorSpreadsheetId}
          />
        </div>
        <div className={styles.dropdownWrapper}>
          <div>{t('Wizard:ManagerNotify:Google:Settings:Sheet')}</div>
          <JustSelect
            fullWidth
            onChange={onChangeSheet}
            isLoading={getListOfSheetsState.loading}
            options={getListOfSheetsState.result || []}
            value={currentSheetId}
            position='fixed'
          />
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(GoogleSettingsModal);
