import {
  GET_APP_CONFIG_FULFILLED,
  GET_APP_CONFIG_PENDING,
  GET_APP_CONFIG_REJECTED,
  GET_OPTIONS_FULFILLED,
} from '../../constants/appConfig.actions';
import { getUserLanguage } from '../../pipes/pureFunctions';

const InitialState = {
  systemFeatures: [],
  zenflow: {},
  reCaptchaEnabled: false,
  reCaptchaSiteKey: null,
  getUserLanguage: null,
  fetching: false,
  fetched: false,
  errors: [],
  cloudDomains: {},
  productNames: {},
  metaSettings: undefined,
  universalLoginMenuEnabled: false,
  usersCanChangeEmail: false,
  usersCanChangePassword: false,
  usersCanResetPassword: false,
  userNeedToSpecifyCountryIsoCode: false,
};

export default function AppConfigReducer(state = InitialState, action) {
  switch (action.type) {
    case GET_APP_CONFIG_PENDING: {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }
    case GET_APP_CONFIG_FULFILLED: {
      const { data } = action.payload;
      const { host, href } = window.location;
      let id = null;
      if (data.zenflow?.euroInstance) {
        id = 'GTM-5KSFR96';
      } else if (href.startsWith('https://app.aimylogic.com/')) {
        id = 'GTM-KHB42L5';
      } else if (host.endsWith('lo.test-ai.net')) {
        id = 'GTM-PFWDJ2F';
      }
      if (data.zenflow?.showHelp && window.loadGoogleTagManager2) {
        window.loadGoogleTagManager2(id);
      }
      if (data.zenflow?.showHelp && window.loadGoogleTagManager) {
        window.loadGoogleTagManager(id);
      }
      if (action.payload.data.zenflow?.showHelp && window.loadCrispSupport) {
        if (action.payload.data.zenflow?.euroInstance) {
          window.CRISP_WEBSITE_ID = 'b87cb14f-2b89-4129-8f06-1e08d092baa9';
        }
        window.loadCrispSupport(getUserLanguage()?.slice(0, 2).toUpperCase() || 'EN');
      }
      if (data.zenflow?.showHelp && !data.zenflow?.euroInstance && data.experrtoEnabled && window.loadExperrtoScript) {
        window.loadExperrtoScript(getUserLanguage()?.slice(0, 2).toUpperCase() || 'EN');
      }

      let unavailableBlocks = data.zenflow.unavailableBlocks;
      if (!data.zenflow.integrationsEnabled && !unavailableBlocks.includes('GoogleSheets')) {
        unavailableBlocks.push('GoogleSheets');
      }

      return {
        ...state,
        fetching: false,
        fetched: true,
        systemFeatures: action.payload.data.systemFeatures,
        metaSettings: action.payload.data.metaSettings,
        zenflow: {
          ...action.payload.data.zenflow,
          unavailableBlocks,
          integrationsEnabled: !!action.payload.data.zenflow.integrationsEnabled,
          euroInstance: !!action.payload.data.zenflow.euroInstance,
          availableInterviewBanners: action.payload.data.zenflow.availableInterviewBanners,
        },
        reCaptchaEnabled: action.payload.data.reCaptchaEnabled,
        reCaptchaSiteKey: action.payload.data.reCaptchaSiteKey,
        getUserLanguage: action.payload.data.getUserLanguage,
        isReporterEnabled: action.payload.data.reporterEnabled || action.payload.data.zenflow.reporterEnabled,
        chatWidgetSecurityEnabled: action.payload.data.chatWidgetSecurityEnabled,
      };
    }
    case GET_APP_CONFIG_REJECTED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }

    case GET_OPTIONS_FULFILLED: {
      return {
        ...state,
        cloudDomains: action.payload.data.domains,
        productNames: action.payload.data.productNames,
        ccSessionsAreEnabled: action.payload.data.authorization?.ccSessionsAreEnabled,
        universalLoginMenuEnabled: action.payload.data.universalLoginMenuEnabled,
        usersCanChangeEmail: action.payload.data.security.usersCanChangeEmail,
        usersCanChangePassword: action.payload.data.security.usersCanChangePassword,
        usersCanResetPassword: action.payload.data.security.usersCanResetPassword,
        userNeedToSpecifyCountryIsoCode: action.payload.data.registration.userNeedToSpecifyCountryIsoCode,
        hidePartnerBankDetails: action.payload.data.hidePartnerBankDetails,
      };
    }

    default: {
      return state;
    }
  }
}
